import * as React from 'react';

import ContactUsPage from '@lce/gatsby-theme-multi-site/src/pages/contact-us';
import { IDefaultPageProps } from '@lce/intl-types/src/IDefaultPageProps';

const Contacto: React.FC<IDefaultPageProps> = props => (
  <ContactUsPage {...props} />
);

export default Contacto;
